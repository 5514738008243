
export default function scrollEvent() {

  const elements = [...document.querySelectorAll('.js-fadein')];

  const options = {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0
  };

  const observer = new IntersectionObserver(test, options);
  elements.forEach((element) => observer.observe(element));

  function test(entries) {
    const [{ isIntersecting, target }] = entries;
    console.log(entries);

    if (isIntersecting) {
      const name = target.dataset.animation;
      active(name,target);
      observer.unobserve(target)
    }
  }

  function active(name,target) {

      if (name === 'area') {
        target.classList.add('isPlay');
      }
      if (name === 'palent') {
        target.classList.add('isActive');
      };
  };
}
