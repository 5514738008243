import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
export default function menu(accordion) {

  document.addEventListener("DOMContentLoaded", () => {

    if (window.matchMedia('(min-width: 1081px)').matches) {
      // const hvBtns = [...document.querySelectorAll('.hvBtn')];
      // hvBtns.forEach( function( hvBtn ) {

      //   hvBtn.addEventListener('mouseover', (event) => {
      //     event.currentTarget.classList.add('is-active');
      //   }, false);

      //   hvBtn.addEventListener('mouseleave', (event) => {
      //     event.currentTarget.classList.remove('is-active');
      //   }, false);

      // });

      $(".hvBtn").hover(function () {
        $(this).children('.sublist').stop().slideDown(300);
      }, function () {
        $(this).children('.sublist').stop().slideUp(300);
      });

    } else {
      const acdBtns = [...document.querySelectorAll('.acdBtn')];

      function test(event) {
        const sublist = event.currentTarget.nextElementSibling;
        event.currentTarget.classList.toggle('is-active');
        sublist.classList.toggle('is-open');
      }

      acdBtns.forEach((acdBtn) => {
        acdBtn.addEventListener('click', test, false);
      });

      //  reservation
      var state = false;
      var scrollpos;
      $(".hvBtn").on("click", function () {
        if (state == false) {
          document.documentElement.style.overflow = 'hidden';
          disableBodyScroll(document.querySelector('.reservation-modal'));
          $(this).children('.reservation-modal').addClass('open');
          state = true;
        } else {
          document.documentElement.style.overflow = '';
          enableBodyScroll(document.querySelector('.reservation-modal'));
          $(this).children('.reservation-modal').removeClass('open');
          state = false;
        }
      });

      $('.reservation-modal__btn').on('click', function () {
        $('.hvBtn').click();
      });
    }
  });
}
