import Swiper from 'swiper';

export default function swiper() {

  let mySwiper = new Swiper ('.swiper-container', {
    effect: 'fade',
    speed: 1500,
    loop: true,
    loopAdditionalSlides: 3,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    autoplay: {
        delay: 2500,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false
    }
  });
}

