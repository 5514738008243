import "@babel/polyfill";
import "picturefill";
import objectFitImages from "object-fit-images";
import "intersection-observer";

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// スクロールで背景色変更
import header from "./modules/header";
header();

// アコーディオン
import accordion from "./modules/accordion";
accordion();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();


/* Script
==================== */
objectFitImages();
